.NotFound {
    color: #111827;
    padding: 3rem;
    width: 100%;

    @media screen and (max-width: 768px) {
        padding: 3rem 2rem;
    }

    .banner-text {
        max-width: 560px;
        margin-left: 44px;

        @media screen and (max-width: 1023px) {
            max-width: 100%;
            text-align: center;
            margin-top: 20px;
            margin-left: 0;
        }

        h1 {
            color: #3c475b;
            font-size: 56px;
            font-weight: 600;
            letter-spacing: 0.28px;

            @media screen and (max-width: 520px) {
                font-size: 50px;
            }
        }

        p {
            font-size: 18px;
            letter-spacing: -0.18px;
            margin-top: 3rem;
            margin-bottom: 46px;
        }

        a {
            font-size: 20px;
            color: #2563eb;
            letter-spacing: -0.2px;
            font-weight: 500;
        }
    }

    .helpful-links {
        margin-top: 117px;
        font-size: 16px;
        font-weight: 500;
        letter-spacing: -0.16px;

        @media screen and (max-width: 1023px) {
            margin-top: 60px;
        }

        p {
            margin-bottom: 37px;
        }

        .helpful-links-box {
            width: 200px;

            @media screen and (max-width: 768px) {
                width: 50%;
                margin-bottom: 20px;
            }

            @media screen and (max-width: 419px) {
                width: 100%;
                margin-bottom: 20px;
            }

            a {
                color: #3b82f6;
                display: inline-block;
            }
        }
    }
}
