.ConsumerProfileBanner {
    background-color: #3b82f6;
    z-index: 10;
    position: relative;
    height: 20rem;
    @media screen and (max-width: 768px) {
        height: 16rem;
    }
    @media screen and (max-width: 575px) {
        height: 22rem;
    }

    &:after {
        content: '';
        z-index: 10;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background: rgba(64, 64, 64, 0.75);
        background: -moz-linear-gradient(45deg, rgba(0, 0, 0, 0.75) 0%, rgba(255, 255, 255, 0) 100%);
        background: -webkit-gradient(
            left top,
            right bottom,
            color-stop(0%, rgba(0, 0, 0, 0.75)),
            color-stop(100%, rgba(255, 255, 255, 0))
        );
        background: -webkit-linear-gradient(45deg, rgba(0, 0, 0, 0.75) 0%, rgba(255, 255, 255, 0) 100%);
        background: -o-linear-gradient(45deg, rgba(0, 0, 0, 0.75) 0%, rgba(255, 255, 255, 0) 100%);
        background: -ms-linear-gradient(45deg, rgba(0, 0, 0, 0.75) 0%, rgba(255, 255, 255, 0) 100%);
        background: linear-gradient(45deg, rgba(0, 0, 0, 0.75) 0%, rgba(255, 255, 255, 0) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#404040', endColorstr='#ffffff', GradientType=1);
    }

    > section {
        z-index: 20;
    }

    .content-type {
        > div > div {
            line-height: 1;
        }

        .content-type-radio {
            label {
                border: 0;

                &:not(.ant-radio-button-wrapper-checked) {
                    background: #d3ecff;
                }

                &:not(:first-child) {
                    &:before {
                        background: transparent;
                    }
                }

                &:first-child {
                    border: 0;
                    border-bottom-left-radius: 0;
                }

                &:last-child {
                    border-bottom-right-radius: 0;
                }
            }
        }
    }

    .content-textarea {
        border-top-left-radius: 0;
        resize: none;
        border-color: #edf2f7;

        &:focus {
            border-color: #d3edff;
            box-shadow: none;
        }
    }

    .editProfileImageButton {
        bottom: 3px;
        right: 15px;
        @media screen and (max-width: 767px) {
            bottom: 0;
            right: 5px;
        }
    }

    .noAvatar {
        height: 175px;
        width: 175px;
        border-radius: 100%;
        overflow: hidden;
        border: 4px solid #3b82f6;
        background-color: #fff;
        margin: 0.5rem;

        .noAvatarButtonWrapper {
            line-height: 175px;
            text-align: center;
            @media screen and (max-width: 767px) {
                line-height: 138px;
            }
        }

        @media screen and (max-width: 767px) {
            height: 138px;
        }
        @media screen and (max-width: 575px) {
            width: 138px;
        }
    }

    .hasAvatar {
        height: 175px;
        width: 175px;
        border-radius: 100%;
        border: 4px solid #3b82f6;
        margin: 0.5rem;
        @media screen and (max-width: 767px) {
            height: 138px;
        }
        @media screen and (max-width: 575px) {
            width: 138px;
        }
    }
}
