.photoPageContainer {
    .photoPageBox {
        height: 200px;
        min-width: 21%;
        background-position: center center;
        background-size: cover;
        background-repeat: no-repeat;
        @media only screen and (max-width: 1199px) {
            min-width: 46%;
            height: 250px;
        }
        @media screen and (max-width: 420px) {
            height: 200px;
        }
    }
}
