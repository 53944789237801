.consumer-content-form {
    background: #bcddef;

    :global {
        .content-type {
            > div > div {
                line-height: 1;
            }

            .content-type-radio {
                label {
                    border: 0;

                    &:not(.ant-radio-button-wrapper-checked) {
                        background: #d3ecff;
                    }

                    &:not(:first-child) {
                        &:before {
                            background: transparent;
                        }
                    }

                    &:first-child {
                        border: 0;
                        border-bottom-left-radius: 0;
                    }

                    &:last-child {
                        border-bottom-right-radius: 0;
                    }
                }
            }
        }
    }

    :global {
        .content-textarea {
            border-top-left-radius: 0;
            border: none;
            resize: none;
            border-color: #edf2f7;

            &:focus {
                border-color: #d3edff;
                box-shadow: none;
            }

            padding-top: 15px;
        }
    }

    .PostFormBtnWrapper {
        transition: all ease 0.3s;

        &.showBtn {
            height: 42px;

            button {
                height: 32px;
                opacity: 1;

                > span {
                    height: 21px;
                    display: inline-block;
                }
            }
        }

        &.hideBtn {
            height: 0;

            button {
                height: 0;
                border-color: transparent;
                opacity: 0;

                > span {
                    height: 0px;
                }
            }
        }
    }

    .CheckInBox {
        transition: all ease 0.3s;
        overflow: hidden;

        &.showDropDown {
            height: 40px;
            opacity: 1;

            > div {
                height: 40px;
                opacity: 1;
            }
        }

        &.hideDropDown {
            height: 0;
            opacity: 0;

            > div {
                height: 0;
                opacity: 0;
            }
        }

        :global {
            .ant-select-selection.ant-select-selection--single {
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
            }
        }
    }
}
