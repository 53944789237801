.photosDiv {
    .photosContainer {
        .photosBox {
            height: 250px;
            background-position: center center;
            background-size: cover;
            background-repeat: no-repeat;
            @media screen and (max-width: 420px) {
                height: 200px;
            }
        }

        .photosHalfImageBox {
            min-width: 46%;
        }

        .photosFullImageBox {
            min-width: 98%;
        }
    }
}
