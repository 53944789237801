.businessSideContent {
    width: 190px;

    :global {
        .businessPageMenu {
            flex: 1 1 0%;

            &.ant-menu-horizontal {
                border: none;

                li.ant-menu-item {
                    padding: 0 8px;
                    margin: 0;
                    line-height: 34px;
                    height: 34px;
                    color: #000;
                    border-radius: 4px;

                    &:hover {
                        background-color: #75dfff;
                        color: #fff;
                    }

                    &.ant-menu-item-selected {
                        font-weight: 600;
                        color: #fff;
                    }
                }
            }

            &.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
                background-color: #3b82f6;
            }
        }
        .ant-btn-primary {
            font-weight: 600;
        }
    }
}

.businessMainContent {
    width: 822px;
}

// .businessPageMenu.ant-menu-vertical {
//     border: none;
// }

// .businessPageMenu.ant-menu-vertical li.ant-menu-item {
//     padding: 0 8px;
//     margin: 0;
//     line-height: 34px;
//     height: 34px;
//     color: #000;
//     border-radius: 4px;
// }

// .businessPageMenu.ant-menu-vertical li.ant-menu-item:hover {
//     background-color: #75dfff;
//     color: #fff;
// }

// .businessPageMenu.ant-menu-vertical li.ant-menu-item.ant-menu-item-selected {
//     font-weight: 600;
//     color: #fff;
// }

// .businessPageMenu.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
//     background-color: #3b82f6;
// }

@media only screen and (max-width: 575px) {
    .businessPageMenuWrapper {
        height: 52px;
    }
}
