.contactUsButton {
    color: #fff;
    background-color: #3b82f6;
    border-color: #3b82f6;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.035);
    padding: 6px 15px;
    border-radius: 999px;
    white-space: nowrap;
    height: 32px;
    font-weight: bold;
}

.contactUsButton:hover,
.contactUsButton:focus {
    color: #fff;
    background-color: #75dfff;
    border-color: #75dfff;
}

.contactUsButton.contactUsButtonDisabled {
    background-color: lightgrey;
    text-shadow: none;
}
