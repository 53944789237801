.ConsumerProfilePage {
    width: 960px;
    margin: 0 auto;
}

@media only screen and (max-width: 991px) {
    .ConsumerProfilePage {
        width: auto;
        margin: 0 10px;
    }
}
