.businessCoverBox {
    z-index: 10;
    position: relative;
    height: 313px;
    margin-bottom: 1.25rem;
}

.businessCoverBox.noImage {
    &:after {
        content: '';
        z-index: 10;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background: rgba(64, 64, 64, 0.75);
        background: -moz-linear-gradient(45deg, rgba(0, 0, 0, 0.75) 0%, rgba(255, 255, 255, 0) 100%);
        background: -webkit-gradient(
            left top,
            right bottom,
            color-stop(0%, rgba(0, 0, 0, 0.75)),
            color-stop(100%, rgba(255, 255, 255, 0))
        );
        background: -webkit-linear-gradient(45deg, rgba(0, 0, 0, 0.75) 0%, rgba(255, 255, 255, 0) 100%);
        background: -o-linear-gradient(45deg, rgba(0, 0, 0, 0.75) 0%, rgba(255, 255, 255, 0) 100%);
        background: -ms-linear-gradient(45deg, rgba(0, 0, 0, 0.75) 0%, rgba(255, 255, 255, 0) 100%);
        background: linear-gradient(45deg, rgba(0, 0, 0, 0.75) 0%, rgba(255, 255, 255, 0) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#404040', endColorstr='#ffffff', GradientType=1);
    }
}

.businessUploadCoverButton {
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.businessUploadCoverButtonWithImage {
    top: 1px;
    left: 1px;
}

@media only screen and (max-width: 1199px) {
    .businessCoverBox {
        margin-bottom: 0;
    }
}

@media only screen and (max-width: 768px) {
    .businessCoverBox {
        height: 16rem;
    }
}
