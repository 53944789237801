.businessProfileUploader > .ant-upload {
    display: block;
}

.businessProfileBox {
    z-index: 10;
    position: relative;
    height: 172px;
    width: 172px;
    margin: 0 auto 13px;
    border-radius: 100%;
    overflow: hidden;
    border: 4px solid #3b82f6;
}

.businessUploadProfileButtonWithImage {
    bottom: 0;
    right: 30px;
}

.businessProfileBox.noImage {
    &:after {
        content: '';
        z-index: 10;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background: rgba(64, 64, 64, 0.75);
        background: -moz-linear-gradient(45deg, rgba(0, 0, 0, 0.75) 0%, rgba(255, 255, 255, 0) 100%);
        background: -webkit-gradient(
            left top,
            right bottom,
            color-stop(0%, rgba(0, 0, 0, 0.75)),
            color-stop(100%, rgba(255, 255, 255, 0))
        );
        background: -webkit-linear-gradient(45deg, rgba(0, 0, 0, 0.75) 0%, rgba(255, 255, 255, 0) 100%);
        background: -o-linear-gradient(45deg, rgba(0, 0, 0, 0.75) 0%, rgba(255, 255, 255, 0) 100%);
        background: -ms-linear-gradient(45deg, rgba(0, 0, 0, 0.75) 0%, rgba(255, 255, 255, 0) 100%);
        background: linear-gradient(45deg, rgba(0, 0, 0, 0.75) 0%, rgba(255, 255, 255, 0) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#404040', endColorstr='#ffffff', GradientType=1);
    }
}

.businessUploadProfileButton {
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

@media only screen and (max-width: 1199px) {
    .BusinessUploadProfilePhoto {
        width: 8rem;
        height: 8rem;
        margin: 0 auto 10px;
    }

    .businessProfileBox {
        width: 8rem;
        height: 8rem;
    }

    .businessProfileBox.noImage {
        &:after {
            border-radius: 100%;
        }
    }

    .businessUploadProfileButton,
    .businessUploadProfileButtonWithImage {
        top: auto;
        left: auto;
        right: 0;
        bottom: 6px;
        -webkit-transform: translate(0%, 0%);
        -moz-transform: translate(0%, 0%);
        -ms-transform: translate(0%, 0%);
        -o-transform: translate(0%, 0%);
        transform: translate(0%, 0%);
    }
}
